.form-control {
  margin-top: 14px;
  border-radius: 0;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  height: 34px;
}
.rbt-input-hint {
  display: none !important;
}
