.cWxXdh {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  height: 48px !important;
  border: dashed 2px #0658c2 !important;
  padding: 8px 16px 8px 8px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  flex-grow: 0 !important;
  width: auto !important;
}
@media (max-width: 412px) {
  .cWxXdh {
    min-width: 250px !important;
    overflow-x: hidden !important;
  }
}

.gofnEo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.gofnEo .file-types {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
}
