@font-face {
  font-family: "Sueca Nano";
  font-style: normal;
  font-weight: normal;
  src: local("Sueca Nano"), local("Sueca-Nano"),
    url("../../fonts/SuecaNano-Light.otf") format("opentype");
}

@font-face {
  font-family: "Sueca Nano";
  font-style: normal;
  font-weight: bold;
  src: local("Sueca Nano"), local("Sueca-Nano"),
    url("../../fonts/SuecaNano-Bold.otf") format("opentype");
}

#table::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 10px !important;
  color: #979797 !important;
}

#table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #979797 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media print {
  .no-print {
    display: none !important;
  }
}
