#interactive.viewport {
  width: 640px;
  height: 480px;
}

#interactive.viewport canvas,
#interactive.viewport video {
  float: left;
  width: 640px;
  height: 480px;
}

#interactive.viewport canvas.drawingBuffer,
#interactive.viewport video.drawingBuffer {
  margin-left: -640px;
}

@media (max-width: 603px) {
  #interactive.viewport {
    width: 100%;
    height: 340px;
    overflow: hidden;
    margin: 10px;
  }
  #interactive.viewport canvas,
  #interactive.viewport video {
    margin-top: -50px;
    width: 100%;
    height: 400px;
  }
  #interactive.viewport canvas.drawingBuffer,
  #interactive.viewport video.drawingBuffer {
    margin-left: -100%;
  }
}
