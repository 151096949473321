.cancel-btn {
  width: 200px;
}

.errors-list {
  display: none !important;
}

.cancel-btn {
  margin-right: 20px;
}

@media (max-width: 603px) {
  .cancel-btn {
    margin-bottom: 20px !important;
    margin-right: 0 !important;
  }
  .errors-list {
    display: flex !important;
  }
  .errors-list-top {
    display: none !important;
  }
}
