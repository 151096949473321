.borderContainer {
  margin: 1% 2.25% 1.5% 1.25%;
}

.containerMotivo {
  margin: 1% 2.5% 1% 1.5%;
}

.buttonCointainer {
  display: flex;
  justify-content: space-between;
  align-content: stretch;
}

.buttonContainerChild {
  width: 50%;
  display: flex;
  column-gap: 0.75%;
}

.formField {
  width: 100%;
}

.fieldsContainer {
  height: 89%;
  margin-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imgContainer {
  width: auto;
  height: 480px;
  max-width: 640px;
}

.imgFactura {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttonCancelar {
  background-color: #e0e0e0;
}

.buttonSecondary {
  width: 20.4%;
}

.buttonPrimary {
  width: 20.4%;
}

@media (min-width: 961px) and (max-width: 1280px) {
  .borderContainer {
    margin: 1% 3% 3% 2%;
  }

  .containerMotivo {
    margin: 1% 4% 1% 3%;
  }
}

@media (min-width: 701px) and (max-width: 960px) {
  .borderContainer {
    margin: 1% 4% 3% 3%;
  }

  .containerMotivo {
    margin: 1% 4.25% 1% 3.25%;
  }
}

@media (max-width: 700px) {
  .containerMotivo {
    margin: 1% 6% 6% 3.5%;
  }

  .borderContainer {
    margin: 1% 6% 3% 3%;
  }

  .buttonCointainer {
    flex-wrap: wrap;
    gap: 1%;
  }

  .buttonContainerChild {
    margin-bottom: 1%;
  }

  .buttonSecondary {
    width: 49%;
  }

  .buttonPrimary {
    width: 100%;
  }
}
