.numericCell {
  text-align: right !important;
}

#table::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 3px;
}

#table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
