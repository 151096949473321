@media (max-width: 1200px) {
  .content-container {
    margin: 0 20px;
  }
}

@media (max-width: 960px) {
  .cupon-inner-container {
    border: none !important;
  }

  .qr-canvas {
    width: 200px !important;
    height: 200px !important;
  }
}

@media (max-width: 600px) {
  .cupon-image-container {
    border: none;
    margin-top: 20px !important;
  }

  .qr-canvas {
    width: 128px !important;
    height: 128px !important;
  }
}
