.carousel .control-dots {
  padding: 0 10px;
  text-align: right;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel .slide {
  background-color: rgba(0, 0, 0, 0);
  margin: auto;
}

.carousel .slide img {
  height: auto;
  margin: auto;
  width: 100%;
}

.carousel .slide img.logoImage {
  width: auto;
}

.carousel.carousel-slider {
  height: 100%;
  margin: auto;
}

.carousel.carousel-slider {
  height: 100%;
  margin: auto;
}

.carousel .slider-wrapper {
  height: 100%;
  margin: auto;
}

.carousel .slider {
  height: 100%;
}
