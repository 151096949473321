.solicitud-container {
  margin: 20px auto !important;
}

.solicitud-inner-container {
  padding: 2rem;
}

.cupon-container {
  margin: 0 100px;
}

.main-title {
  text-align: center;
  margin-bottom: 20px !important;
}

.print-btn {
  width: 200px;
  padding: 20px !important;
  margin-right: 20px !important;
}

@media print {
  .cupon-container {
    margin-top: 20px;
    border: 1px solid black;
    -webkit-print-color-adjust: exact;
    -webkit-filter: blur(0);
  }
}

.cupon-image {
  max-height: 250px;
  max-width: 100%;
}

.cupon-inner-container {
  padding: 30px;
}

.cupon-left-container {
  background-color: #d9d9d1;
  border-right: 5px solid #000;
}

.cupon-left-inner-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
}

.cupon-right-container {
  border-left: 5px solid #000;
}

.cupon-right-top {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d1;
}

.cupon-right-bottom {
  padding-top: 20px;
}

.cupon-image-container {
  text-align: center;
  border-left: 1px solid #d9d9d1;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 10px;
}

.no-margin {
  margin: 0 !important;
}

.tipo {
  font-weight: bold;
}

.descripcion {
  padding-right: 10px;
}

@media (max-width: 992px) {
  .solicitud-container {
    margin: 0 !important;
  }

  .solicitud-inner-container {
    padding: 20px 0;
  }
}

@media (max-width: 600px) {
  .main-title {
    margin: 0 20px 20px !important;
  }

  .print-btn {
    margin: 0 auto !important;
    margin-bottom: 20px !important;
    text-align: center;
    display: block !important;
  }
}
